import React from "react"
import { Link } from "gatsby"
import { slide as Menu } from "react-burger-menu"

export default props => {
  return (
    // Pass on our props
    <Menu {...props}>
      <Link to="/#" className="nav-item" activeClassName="active-nav-item">
        Home
      </Link>

      {/**
        
        <Link to="/#about" className="nav-item" activeClassName="active-nav-item">
          About
        </Link>
        */}
      <Link
        to="/#projects"
        className="nav-item"
        activeClassName="active-nav-item"
      >
        Projects
      </Link>

      <Link
        to="/#contact"
        className="nav-item"
        activeClassName="active-nav-item"
      >
        Contact
      </Link>

      {/**
        
        <Link to="/blog" className="nav-item" activeClassName="active-nav-item">
          Blogs
        </Link>
        */}
    </Menu>
  )
}

// <aside className={headerStyles.socialMedias}>
//         <a
//           href="https://www.linkedin.com/in/vincenttern/"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <i className="fab fa-linkedin fa-2x"></i>
//         </a>
//         <a
//           href="https://github.com/vincenttern"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <i className="fab fa-github-square fa-2x"></i>
//         </a>
//       </aside>
