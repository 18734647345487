import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer className="footer__container">
      {/**
    
      <div className="footer__box top">
        
        <div className="footer__box box-1">
          <p className="footer__box--connect">
            Need a Sacramento Web Developer? Let's Connect
          </p>
          <Link className="connectInTouch btn" to="/#contact">
            Get In Touch
          </Link>
        </div>
      
      

   
      
        <div className="footer__box box-2">
          <p className="footer__box find--me">Where to Find Me</p>
          <div className="footer__box social--media">
            <a
              href="https://www.linkedin.com/in/vincenttern/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
            <a
              href="https://github.com/vincenttern"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-github-square"></i>
            </a>

            <a
              href="vincenttern@gmail.com?subject=Important!&body=Hi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="far fa-envelope"></i>
            </a>
          </div>
        </div>
        </div>
   
    */}

      <div className="footer__box bottom">
        <div className="footer__box box-3">
          <p>© Copyright 2020 − {data.site.siteMetadata.author}</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
