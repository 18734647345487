import React from "react"

import Header from "./header"
import Footer from "./footer"
import "../styles/index.scss"

const Layout = props => {
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }

  return (
    <div className="layout">
      <div className="layout__content">
        <Header />
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout
