import React, { useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import SideBarMenu from "./sidebar"

export default function Header() {
  // const data = useStaticQuery(graphql`
  //   query {
  //     site {
  //       siteMetadata {
  //         title
  //         author
  //       }
  //     }
  //   }
  // `)

  useEffect(() => {
    window.addEventListener("scroll", resizeHeaderOnScroll)
  })

  const resizeHeaderOnScroll = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 200,
      headEl = document.getElementById("main-header")

    if (distanceY > shrinkOn) {
      headEl.classList.add("resize")
    } else {
      headEl.classList.remove("resize")
    }
  }

  return (
    <header id="main-header" className="header">
      <SideBarMenu />
      <nav className="navbar">
        <ul className="nav__list">
          {/**
        
          <li className="nav__item">
            <Link
              className="nav__item--link title"
              activeClassName="activeNavItem"
              to="/#"
            >
              {data.site.siteMetadata.author}
            </Link>
          </li>
        */}
          <li className="nav__item">
            <Link
              className="nav__item--link navs"
              activeClassName="activeNavItem"
              to="/#"
            >
              Home
            </Link>
          </li>
          {/**
        
            <li className="nav__item">
              <Link
                className="nav__item--link navs"
                activeClassName="activeNavItem"
                to="/#about"
              >
                About
              </Link>
            </li>
        */}
          <li className="nav__item">
            <Link
              className="nav__item--link navs"
              activeClassName="activeNavItem"
              to="/#projects"
            >
              Projects
            </Link>
          </li>

          <li className="nav__item">
            <Link
              className="nav__item--link navs"
              activeClassName="activeNavItem"
              to="/#contact"
            >
              Contact
            </Link>
          </li>
          {/**
        
            <li className="nav__item">
              <Link
                className="nav__item--link"
                activeClassName="activeNavItem"
                to="/blog"
              >
                Blog
              </Link>
            </li>
        */}
        </ul>
      </nav>
    </header>
  )
}
